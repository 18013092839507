import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import {Helmet} from "react-helmet";
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
    <Seo title="404: Not Found" />
      <header class="masthead">
          <div class="container position-relative px-4 px-lg-5">
              <div class="row gx-4 gx-lg-5 justify-content-center">
                  <div class="col-md-10 col-lg-8 col-xl-7">
                      <div class="site-heading">
                      </div>
                  </div>
              </div>
          </div>
      </header>
    <article
      className="global-wrapper blog-post"
      itemScope
      itemType="http://schema.org/Article"
    >
    <h1 itemProp="headline">404: Página no encontrada</h1>
        <section itemProp="articleBody">
        <p>¡Vaya, parece que la página no existe!</p>
        <p>Es posible que se deba a que el enlace ha desaparecido o se ha movido el contenido.</p>
        <p>Puedes <Link to="/">volver a la página de inicio</Link>.</p>
        </section>
    </article>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
